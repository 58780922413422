<template>
    <div class="">
        <label class="form-label" :for="'custom-field-' + field.id">{{ field.name }}<span v-if="field.required"> *</span></label>
        <div v-if="['single_enum'].includes(field.type)" class="wrapper-radios">
            <label v-for="(option, index) of field.value_options" :key="'single_enum_' + index" class="me-3">
                <input
                    type="radio"
                    :name="field.id"
                    :value="option"
                    @input="onChanged"
                    :checked="option == modelValue"
                    class="form-check-input" /> {{ option }}
            </label>
        </div>

        <Multiselect 
            v-if="['multi_enum'].includes(field.type)"
            :value="modelValue"
            v-model="multienum"
            @input="multiEnumChange"
            class="wrapper-multienum"
            :id="'custom-field-' + field.id"
            mode="tags"
            :multiple="true"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="field.value_options.map(opt => ({value:opt, label:opt}))"
            :placeholder="field?.placeholder"
            ref="multiselect" />

        <flat-pickr
            v-if="['date'].includes(field.type)"
            :value="modelValue"
            @input="onChanged"
            v-model="date"
            :config="configDate"
            :placeholder="field?.placeholder"
            :id="'custom-field-' + field.id"
            class="form-control flatpickr-input" />

        <flat-pickr
            v-if="['datetime'].includes(field.type)"
            :value="modelValue"
            @input="onChanged"
            v-model="datetime"
            :config="configDateTime"
            :placeholder="field?.placeholder"
            :id="'custom-field-' + field.id"
            class="form-control flatpickr-input" />

        <input
            v-if="['string'].includes(field.type)"
            type="text"
            :id="'custom-field-' + field.id"
            :class="['form-control', {'is-valid': validation === true}, {'is-invalid': validation !== null && validation !== true}]"
            :value="modelValue"
            @input="onChanged"
            @keyup="validate"
            @blur="clearIncompletedField"
            v-maska="mask"
            :maxlength="field.limit_size"
            :placeholder="field?.placeholder" />

        <input
            v-if="['float'].includes(field.type)"
            type="number"
            :id="'custom-field-' + field.id"
            :class="['form-control', {'is-valid': validation === true}, {'is-invalid': validation !== null && validation !== true}]"
            :value="modelValue"
            @input="onChanged"
            @keyup="validate"
            :maxlength="field.limit_size"
            :placeholder="field?.placeholder"
            step="0.1" />

        <input
            v-if="['integer'].includes(field.type)"
            type="number"
            :id="'custom-field-' + field.id"
            :class="['form-control', {'is-valid': validation === true}, {'is-invalid': validation !== null && validation !== true}]"
            :value="modelValue"
            @input="onChanged"
            @keyup="validate"
            :maxlength="field.limit_size"
            :placeholder="field?.placeholder"
            step="1"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />

        <div class="invalid-feedback">{{ validation }}</div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import flatPickr from "vue-flatpickr-component";

import "flatpickr/dist/flatpickr.css";
import "@vueform/multiselect/themes/default.css";

export default {
    name: 'CustomField',
    components: {
      Multiselect,
      flatPickr
    },
    props: {
        modelValue: [String, Number, Array],
        field: {
            type: Object,
            required: true,
            validator(value) {
                return !(value.name === undefined || value.name === null || value.type === undefined || value.type === null);
            }
        }
    },
    data() {
        return {
            validation: null,
            date:null,
            datetime: null,
            multienum: this.modelValue,
            configDate: {
                wrap: true,
                allowInput: true,
                dateFormat: "Y-m-d",
                altInput: true,
                altFormat: "d/m/Y"
            },
            configDateTime: {
                wrap: true,
                allowInput: true,
                enableTime: true,
                dateFormat: "Y-m-dTH:i:S",
                altInput: true,
                altFormat: "d/m/Y H:i"
            }
        }
    },
    computed: {
        mask() {
            if (this.field.mask?.length) {
                switch (this.field.mask) {
                    case 'cnpj':
                        return '##.###.###/####-##';
                    case 'cpf':
                        return '###.###.###-##';
                    case 'cep':
                        return '#####-###';
                    case 'telephone':
                        return ['+##############', '+#############', '+############', '+###########', '+##########'];
                    default:
                        return '';
                }
            }
            return '';
        }
    },
    methods: {
        onChanged(event) {
            let value = ['integer', 'float'].includes(this.field.type) && event.currentTarget.value.length
                ? parseFloat(event.currentTarget.value)
                : event.currentTarget.value;
            this.$emit('update:modelValue', value);
        },
        multiEnumChange(event) {
            this.$emit('update:modelValue', event);
        },
        validate() {
            this.validation = null;
            if (this.field.regex_validation?.length) {
                const regex = new RegExp(this.field.regex_validation);
                this.validation = (!regex.test(this.modelValue) ? 'Valor inválido' : true);
            }
        },
        clearIncompletedField() {
            if (this.field.mask?.length) {
                let size = this.mask.length;
                if (Array.isArray(this.mask)) {
                    size = this.mask.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue.length), '999999999999999999');
                }
                if (this.modelValue.length < size) {
                    this.$emit('update:modelValue', '');
                }
            }
        }
    }
};
</script>

<style scoped>
.wrapper-radios {
    min-height: 37.5px;
}
.wrapper-multienum {
    padding: 0;
    min-height: 37.5px !important;
}
</style>