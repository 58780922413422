<script>
  import Layout from "@/layouts/main.vue";
  import appConfig from "@/../app.config";
  import PageHeader from "@/components/page-header";
  import CustomField from "@/components/custom-field";
  import { viewEntity, updateEntity, saveEntity, callUrl } from '@/helpers/api-apolo';
  import { saError, saSuccess, formatPrice, sumBusinessProductPrices } from '@/helpers/global-functions';
  import { overlay } from "@/state/helpers";
  import * as helpersFunction from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Criação/Edição de Pessoa",
      meta: [{
        name: "description",
        content: appConfig.description,
      }],
    },
    data() {
      return {
        title: "Criação/Edição de Pessoa",
        items: [
          {
            text: "Crm",
            href: "/",
          },
          {
            text: "Pessoas",
            href: "/people",
          },
          {
            text: "Criação/Edição de Pessoa",
            active: true,
          },
        ],
        additionalFields: [],
        businesses: [],
        pipelines: {},
        data: {
          custom_field: {}
        },
        validation: {
          email: null,
          telephone: null,
        }
      };
    },
    components: {
      Layout,
      PageHeader,
      CustomField
    },
    methods: {
      ...overlay,
      ...helpersFunction,
      goBack() {
        this.$router.push({ name: 'people-list' });
      },
      async save() {
        Object.keys(this.validation).forEach(key => {
            this.validation[key] = true;
        });
        if (this.data.email?.length == 0 && this.data.telephone?.length == 0) {
            this.validation['email'] = 'E-mail ou Telefone são obrigatórios';
            this.validation['telephone'] = 'E-mail ou Telefone são obrigatórios';
        }
        const invalidElements = Object.keys(this.validation).filter(field => this.validation[field] !== true);
        if (invalidElements.length) {
          return;
        }
        if (this.additionalFields.length) {
          for (let i=0; i<this.additionalFields.length; i++) {
            if (this.additionalFields[i].required && (!this.data.custom_field[this.additionalFields[i].id] || !this.data.custom_field[this.additionalFields[i].id].toString().length)) {
              saError(`O campo "${this.additionalFields[i].name}" é obrigatório`);
              return;
            }
          }
        }
        const allowedCustomFields = this.additionalFields.map(field => field.id);
        this.changeShowOverlay({show: true});
        const dataToSave = JSON.parse(JSON.stringify(this.data));
        dataToSave.custom_field = Object.keys(dataToSave.custom_field)
          .filter((k) => dataToSave.custom_field[k].toString().length && allowedCustomFields.includes(k))
          .reduce((a, k) => ({ ...a, [k]: dataToSave.custom_field[k] }), {});
        dataToSave.custom_field = Object.keys(dataToSave.custom_field).map(key => ({field_id: key, value: dataToSave.custom_field[key]}));
        let result = {};
        try {
          if (dataToSave.id) {
            result = await updateEntity(dataToSave, 'person');
          } else {
            result = await saveEntity(dataToSave, 'person');
          }
          if (result.id) {
            saSuccess("Pessoa salva com Sucesso!");
            this.data.id = result.id;
            this.changeShowOverlay({show: false});
          }
        } catch (error) {
          this.changeShowOverlay({show: false});
          saError(error.message);
        }
      },
      async getAdditionalFields() {
        this.additionalFields = []
        const dataM = await callUrl({}, '/custom-field?entity=person&order=order:1', 'get')
        if (dataM && dataM.length) {
          this.additionalFields = dataM;
          for (let i=0; i < dataM.length; i++) {
            if (this.data.custom_field[dataM[i].id] === undefined) {
              this.data.custom_field[dataM[i].id] = (dataM[i].type === 'multi_enum' ? [] : '');
            }
          }
        }
      },
      async getBusinesses() {
        try {
          const pipelines = await callUrl({}, `/pipeline`, 'get');
          if (pipelines && pipelines.length) {
            for (let i = 0; i < pipelines.length; i++) {
              this.pipelines[pipelines[i].id] = pipelines[i].name;
              const businesses = await callUrl({}, `/pipeline/${pipelines[i].id}/business?person_id=${this.data.id}`, 'get');
              if (businesses && businesses.length) {
                this.businesses = this.businesses.concat(businesses);
              }
            }
          }
        } catch (error) {
          console.error(`Falha ao buscar os negócios da pessoa: ${error.message}`);
        }
      },
      getProductsPrice(products) {
        return formatPrice(sumBusinessProductPrices(products));
      }
    },
    async mounted() {
      if (!this.$route.params.id) {
        return this.$router.push({ name: 'people-list' });
      }
      this.changeShowOverlay({show: true});
      this.title = "Edição de Pessoa";
      this.items[2]['text'] = "Edição de Pessoa";
      const data = await viewEntity(this.$route.params.id, 'person');
      if (!data) {
        return this.$router.push({ name: 'people-list' });
      }
      data.custom_field = {};
      if (data.custom_field && data.custom_field.length) {
        const oldCustomFields = JSON.parse(JSON.stringify(data.custom_field));
        for (let i=0; i<oldCustomFields.length; i++) {
          data.custom_field[oldCustomFields[i].field_id] = oldCustomFields[i].value;
        }
      }
      this.data = data;
      this.getAdditionalFields();
      this.getBusinesses();
      this.changeShowOverlay({show: false});
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <form>
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Informações Gerais</h5>
            </div>
            <div class="card-body row">
              <div class="col-lg-4 col-sm-12 mb-3">
                <label class="form-label" for="name-input">Nome</label>
                <input type="text" :class="['form-control']" id="name-input" v-model="data.name" placeholder="Digite o nome do produto" />
              </div>
              <div class="col-lg-4 col-sm-6 mb-3">
                <label class="form-label" for="email-input">E-mail</label>
                <input type="text" :class="['form-control', {'is-valid': validation.email === true}, {'is-invalid': validation.email !== null && validation.email !== true}]" id="email-input" v-model="data.email" placeholder="Digite o Email" />
                <div class="invalid-feedback">{{ validation.email }}</div>
              </div>
              <div class="col-lg-4 col-sm-6 mb-3">
                <label class="form-label" for="telephone-input">Telefone</label>
                <input type="text" :class="['form-control', {'is-valid': validation.telephone === true}, {'is-invalid': validation.telephone !== null && validation.telephone !== true}]" id="telephone-input" v-model="data.telephone" placeholder="Digite o Telefone" />
                <div class="invalid-feedback">{{ validation.telephone }}</div>
              </div>
            </div>
          </div>

          <div :class="['card', {'d-none': !additionalFields.length}]">
            <div class="card-header">
              <h5 class="card-title mb-0">Campos Adicionais</h5>
            </div>
            <div class="card-body row">
              <CustomField
                v-for="(field, index) of additionalFields"
                :key="'custom_field_' + index"
                class="col-lg-6 col-sm-12 mb-3"
                :field="field"
                v-model="data.custom_field[field.id]" />
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Negócios</h5>
              <div class="swiper project-swiper d-flex flex-row flex-rap justify-content-start" style="flex-wrap: wrap !important;">
                <div class="swiper-wrapper me-auto mt-4" v-for="business of businesses" :key="business.id">
                  <div :class="[
                    'card',
                    'profile-project-card',
                    'shadow-none',
                    'mb-0',
                    {'profile-project-success': business.status === 'Fechado'},
                    {'profile-project-danger': business.status === 'Perdido'},
                    {'profile-project-info': business.status === 'Aberto'},
                    {'profile-project-warning': business.status === 'Removido'}
                  ]">
                    <div class="card-body p-4">
                      <div class="d-flex">
                        <div class="flex-grow-1 text-muted overflow-hidden">
                          <h5 class="fs-14 text-truncate mb-1">
                            <router-link :to="{ name: 'deal-details', params: { id: business.id } }" class="text-dark"><span v-if="business.code">#{{ business.code }} - </span>{{ business.title }}</router-link>
                          </h5>
                        </div>
                        <div class="flex-shrink-0 ms-4">
                          <span v-if="business.status === 'Fechado'" class="badge bg-soft-success badge-soft-success fs-10">Ganho</span>
                          <span v-if="business.status === 'Perdido'" class="badge bg-soft-danger badge-soft-danger fs-10">Perdido</span>
                          <span v-if="business.status === 'Aberto'" class="badge bg-soft-info badge-soft-info fs-10">Aberto</span>
                          <span v-if="business.status === 'Removido'" class="badge bg-soft-warning badge-soft-warning fs-10">Removido</span>
                        </div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="flex-grow-1">
                          <p class="text-muted text-truncate mb-0">Criado em: <span class="fw-semibold text-dark">{{ getDiffDateFriendly(business.created_at) }}</span></p>
                          <p class="text-muted text-truncate mb-0">Última Atualização: <span class="fw-semibold text-dark">{{ getDiffDateFriendly(business.updated_at) }}</span></p>
                          <p class="text-muted text-truncate mb-0">Pipeline: <span class="fw-semibold text-dark">{{ this.pipelines[business.pipeline_id] }}</span></p>
                          <p class="text-muted text-truncate mb-0">Produtos: <span class="fw-semibold text-dark">{{ business.products.length }} ({{ getProductsPrice(business.products) }})</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="hstack text-end mb-3 justify-content-end gap-2">
            <b-button variant="outline-dark" class="waves-effect waves-light" @click.prevent="goBack">Cancelar</b-button>
            <button type="button" class="btn btn-success w-sm" @click.prevent="save">Salvar</button>
          </div>
        </form>
      </div>
    </div>
    
  </Layout>
</template>

<style scoped>
</style>
